import React from 'react';
import HomeScreen from '../screens/HomeScreen';
import NewGameScreenJogador from '../screens/NewGameScreenJogador';
import NewGameScreenPalpite from '../screens/NewGameScreenPalpite';
import NewGameScreenConferir from '../screens/NewGameScreenConferir';
import NewGameScreenImprimir from '../screens/NewGameScreenImprimir';
import MenuScreen from '../screens/MenuScreen';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import { Button } from 'react-native-paper';
import LogoTitle from '../components/LogoTitle';
import { useAuth } from '../contexts/auth';

const Tab = createBottomTabNavigator();



function NewGame() {
  return (
    <Tab.Navigator initialRouteName='Home'>
      <Tab.Screen 
          name="Home" 
          component={HomeScreen} 
          options={
            { 
              unmountOnBlur: true,
              headerShown: false,
              tabBarLabel: 'Início',
              title: 'Escolha o Concurso',
              headerStyle: {
                backgroundColor: 'rgba(24, 128, 56, .4)',
                alignContent: "center",
                height: 30,
              },
              
              headerTintColor: '#fff',
              headerTitleStyle: {
                fontWeight: 'bold',
              },
              tabBarStyle: { display: "none" },
              
            }
          }
          />
      <Tab.Screen 
        name="NewGameScreenJogador" 
        component={NewGameScreenJogador} 
        options={{
          unmountOnBlur: true,
          title: "Nome do JOGADOR",
          headerStyle: {
            backgroundColor: 'rgba(24, 128, 56, .4)',
            alignContent: "center",
            height: 30,
          },
          headerTintColor: "#FFF",
          headerTitleAlign: "center",
          tabBarStyle: { display: "none" },
        }}
        />
      <Tab.Screen 
        name="NewGameScreenPalpite" 
        component={NewGameScreenPalpite} 
        options={{
          unmountOnBlur: true,
          title: "PALPITES",
          headerStyle: {
            backgroundColor: 'rgba(24, 128, 56, .4)',
            alignContent: "center",
            height: 30,
          },
          headerTintColor: "#FFF",
          headerTitleAlign: "center",
          tabBarStyle: { display: "none" },
        }}
        />
      <Tab.Screen 
        name="NewGameScreenConferir" 
        component={NewGameScreenConferir} 
        options={{
          unmountOnBlur: true,
          title: "Conferir Bilhetes",
          headerStyle: {
            backgroundColor: 'rgba(24, 128, 56, .4)',
            alignContent: "center",
            height: 30,
          },
          headerTintColor: "#FFF",
          headerTitleAlign: "center",
          tabBarStyle: { display: "none" },
        }}
        />
      <Tab.Screen 
        name="NewGameScreenImprimir" 
        component={NewGameScreenImprimir} 
        options={{
          unmountOnBlur: true,
          title: "TICKETS GERADOS",
          headerStyle: {
            backgroundColor: 'rgba(24, 128, 56, .4)',
            alignContent: "center",
            height: 30,
          },
          headerTintColor: "#FFF",
          headerTitleAlign: "center",
          tabBarStyle: { display: "none" },
        }}
        />
      
    </Tab.Navigator>
  );
}


const AppRoutes = () => {
    const { operador } = useAuth();
    return (
      <Tab.Navigator
        screenOptions={({ route }) => ({
          tabBarIcon: ({ color, size }) => {
            let iconName = "home-outline";
  
            if (route.name === 'Home') {
              return <Ionicons name="md-home" size={24} color="green" />;
            } else if (route.name === 'NewGame') {
              return <Ionicons name="add-circle" size={24} color="green" />;
            } else if (route.name === 'ListGames') {
              return <Ionicons name="list-circle" size={24} color="green" />;
            } else if (route.name === 'Menu') {
              return <Ionicons name="help-circle-outline" size={24} color="green" />;
            }
  
            return <AntDesign name="stepforward" size={24} color="green" />;
          },
          "tabBarLabelStyle": {
            "fontSize": 12,
            "fontWeight": "500",
            "color": "green",
          },
          "tabBarStyle": [
            {
              //"display": "none"
            },
            null
          ],
          headerTitle: (props) => <LogoTitle />, 
          headerRight: () => (
            <Button textColor='#FFF'>OPERADOR: <br></br>{operador?.apelido}</Button>
          ),
        })}
        
      >
        
        <Tab.Screen 
          name="NewGame" 
          component={NewGame}
          listeners={({ navigation, route }) => ({
            tabPress: (e) => {
              // Prevent default action
              e.preventDefault();
        
              // Do something with the `navigation` object
              navigation.navigate('NewGame', {screen: "Home"});
            },
          })}
          options={
            { 
              unmountOnBlur: true,
              tabBarLabel: 'Página Inicial',
              title: 'Novo Jogo / P1',
              headerStyle: {
                backgroundColor: 'rgba(24, 128, 56, .4)',
              },
              headerTintColor: '#fff',
              headerTitleStyle: {
                fontWeight: 'bold',
              },
              
              //tabBarStyle: { display: "none" },
            }
          }
          // listeners={({ navigation }) => ({
          //   blur: () => navigation.setParams({ screen: undefined }),
          // })}
        />
        <Tab.Screen name="Menu" component={MenuScreen} 
          listeners={({ navigation, route }) => ({
            tabPress: (e) => {
              // Prevent default action
              e.preventDefault();
        
              // Do something with the `navigation` object
              navigation.navigate('Menu', { redirectHome: 0});
            },
          })} 
          options={
            { 
              unmountOnBlur: true,
              tabBarLabel: 'Sobre o Seninha',
              title: 'Como Jogar',
              headerStyle: {
                backgroundColor: 'rgba(24, 128, 56, .4)',
              },
              headerTintColor: '#fff',
              headerTitleStyle: {
                fontWeight: 'bold',
              },
              
              //tabBarStyle: { display: "none" },
            }
          }
          />
       
      </Tab.Navigator>
  
    );
  }






export default AppRoutes; 