import React, { memo } from 'react';
import { Image, StyleSheet } from 'react-native';

const LogoTitle = () => (
  <Image source={require('../../assets/logo.png')} style={styles.image} />
);

const styles = StyleSheet.create({
  image: {
    width: 70,
    height: 40,
    margin: 5,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
});

export default memo(LogoTitle);
