import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, PixelRatio, Dimensions } from 'react-native';
import Cesta from '../components/cesta';
import { useAuth } from '../contexts/auth';
import { Navigation } from '../../types';
import { Divider, Button, Badge, Card, Avatar, IconButton, ActivityIndicator } from 'react-native-paper';
//import Button from '../components/Button';
import { general, ticketsStyle } from '../../css';
import { addJogo } from '../services/api';
import AsyncStorage from '@react-native-async-storage/async-storage';

type Props = {
    navigation: Navigation;
};

const formatarMoeda = (numero: Number) => "" + numero.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");


const NewGameScreenConferir = ({ navigation }: Props) => {
    const { jogador, jogo, concurso, addJogoGravado, limpaJogosGravados, codigo } = useAuth();
    const [jogoFormatado, setjogoFormatado] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const [listaDeJogos, setListaDeJogos] = useState([]);
    const [key, setKey] = useState(0); // adicionando o estado key
    const [isSaving, setIsSaving] = useState(false);

    
    const gravarBilhete = async () => {
        if (isSaving) return;
        setIsSaving(true);

        setjogoFormatado([]);
        jogo.map((item, index) => (
            jogoFormatado.push(
                { nome: jogador?.nome, whatsapp: jogador?.whatsapp, jogo: item }
            )
        ))

        jogoFormatado.forEach(element => {
            limpaJogosGravados();
            setLoading(true);
            addJogo(concurso?.id, element, codigo.replace('/', ''))
                .then(response => response.data)
                .then((responseJson) => {
                    //console.log('ID GERADO: ', responseJson.data);
                    addJogoGravado(responseJson.data);
                    navigation.navigate('NewGameScreenImprimir');
                })
                .catch((error) => {
                    console.error(error);
                }).finally(() => {
                    setLoading(false);
                    setIsSaving(false);
                });



        });


    }


    const removerJogo = (index: Number) => {
        jogo.splice(index, 1);
        //setKey(key + 1); // atualizando o estado key
        navigation.navigate('NewGameScreenPalpite')
        //navigation.navigate('NewGameScreenImprimir')
    }

    useEffect(() => {
        setListaDeJogos(jogo);
    }, [jogo]); // Apenas re-execute o efeito quando o jogo mudar

    const fontSizeBadge = 27 * Dimensions.get('window').width / 315;

    

    let count = 1
    const listaJogos = listaDeJogos.map(
        (jogolinha, index) => (
            <View style={ticketsStyle.row} key={index}>
                
                <Card style={{ width: "100%", backgroundColor: '#f7f7f7' }}>
                    <Card.Title
                        title={"JOGO " + count++}
                        left={(props) => <Avatar.Icon {...props} icon="format-list-numbered" />}
                        right={(props) => <IconButton {...props} icon="delete" onPress={() => removerJogo(index)} />}
                    />
                    <Card.Content>
                        <View style={{ flexDirection: 'row' }}>
                            <Badge size={fontSizeBadge} style={{backgroundColor: 'green'}}>{jogolinha[0]}</Badge>
                            <Badge size={fontSizeBadge} style={{backgroundColor: 'green'}}>{jogolinha[1]}</Badge>
                            <Badge size={fontSizeBadge} style={{backgroundColor: 'green'}}>{jogolinha[2]}</Badge>
                            <Badge size={fontSizeBadge} style={{backgroundColor: 'green'}}>{jogolinha[3]}</Badge>
                            <Badge size={fontSizeBadge} style={{backgroundColor: 'green'}}>{jogolinha[4]}</Badge>
                            <Badge size={fontSizeBadge} style={{backgroundColor: 'green'}}>{jogolinha[5]}</Badge>
                            <Badge size={fontSizeBadge} style={{backgroundColor: 'green'}}>{jogolinha[6]}</Badge>
                            <Badge size={fontSizeBadge} style={{backgroundColor: 'green'}}>{jogolinha[7]}</Badge>
                            <Badge size={fontSizeBadge} style={{backgroundColor: 'green'}}>{jogolinha[8]}</Badge>
                            <Badge size={fontSizeBadge} style={{backgroundColor: 'green'}}>{jogolinha[9]}</Badge>
                        </View>
                    </Card.Content>
                </Card>
            </View>


        )
    );

    if (loading) {
        return (
            <View>
                <ActivityIndicator size='large' color='#777' />
            </View>
        );
    }

    return (
        <View
            style={{
                flex: 1,
                padding: 2, flexDirection: 'column'
            }}>
            <Cesta
                jogador={jogador?.nome}
                concurso={(concurso ? concurso.titulo : '---').toString()}
                quantidadeJogo={(jogo ? jogo.length : '0').toString()}
                valor={(jogo ? jogo.length * concurso.valor_aposta : '0').toString()}
                whatsapp={jogador?.whatsapp}
            />

            <ScrollView style={{padding:5}} showsVerticalScrollIndicator={false}>
                    <View style={[general.container]}>
                        <View style={ticketsStyle.row} >
                            
                            <Card style={{ width: "100%", backgroundColor: '#f7f7f7' }}>
                                <Card.Content>
                                    <View style={general.emLinhas}>
                                        <Text style={general.text18}>SENINHA: {concurso?.titulo}</Text>
                                        <Text style={general.text18}>TOTAL: R${(jogo ? formatarMoeda(jogo.length * concurso.valor_aposta) : '0').toString()}</Text>
                                        
                                    </View> 
                                    <View style={general.emBlocos}>
                                        <Text style={general.bloco50L}>Jogador: {jogador?.nome}</Text>
                                        <Text style={general.bloco50R}>1º SORTEIO 22/06/2022</Text>
                                    </View>

                                </Card.Content>
                            </Card>
                        </View>

                        {listaJogos}


                    </View>

                    <View style={[general.controlSpace]}>
                        <View style={general.buttonViewFull}>
                            <Button
                                mode="contained"
                                icon="content-save-check"
                                onPress={() => gravarBilhete()}
                            >
                                CONFIRMAR E GERAR PALPITE
                            </Button>
                        </View>
                    </View>
            </ScrollView>

        </View>
    );
}

export default NewGameScreenConferir;
