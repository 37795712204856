import React from 'react';
import { View } from 'react-native';
import { cesta } from '../../css';
import { Chip } from 'react-native-paper';



const formatarMoeda = (numero: Number) => "" + numero.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");

const Cesta = (props: { jogador: String | undefined; whatsapp: string | undefined; concurso: string | undefined; quantidadeJogo: string | undefined; valor: string | undefined;     }) => (
        <View style={[cesta.header]}>
            <View style={[cesta.headerContentLeft]}> 
                <Chip icon="account-outline" mode='flat' style={{marginTop: 1, height: 30, minWidth: '100%'}}>{props.jogador}</Chip>
                <Chip icon="whatsapp" mode='flat' style={{marginTop: 1, height: 30, minWidth: '100%'}}>{props.whatsapp}</Chip>
            </View>
            <View style={[cesta.headerContentRight]}>
                <Chip icon="checkbox-marked-outline" mode='flat' style={{marginTop: 1, height: 30, minWidth: '100%', alignItems: 'flex-start' }}>{props.concurso?.substring(0 , 13)}</Chip>
                <View style={{flexDirection: 'row', width: '100%' }}>
                    <Chip icon="cart-variant" mode='flat' style={{marginTop: 1, height: 30, flexDirection: 'column', minWidth: '50%'}}> {props.quantidadeJogo}</Chip>
                    <Chip icon="cash" mode='flat' style={{marginTop: 1, height: 30, minWidth: '50%'}}> {formatarMoeda(Number(props.valor))}</Chip>
                </View>
            </View>
        </View>

);

export default Cesta;
