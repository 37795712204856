import {StyleSheet} from 'react-native';

const tickets = StyleSheet.create({
    container: {
        flexDirection: 'column', 
        alignItems: 'center',
        backgroundColor: '#ecf0f1',
        padding: 2,
      },
      header: {
          flexDirection: 'column', 
          alignItems: 'flex-start',
          backgroundColor: '#ecf0f1',
          padding: 2,
      },
      emLinhas: {
        textAlign: 'center',
        alignItems: 'center',
      },
      emBlocos: {
          flex: 2,
          flexDirection: 'row',
          minWidth: '50%',
          flexWrap: "nowrap",
      },
      bloco50L: {
          width: '50%',
          textAlign: 'left',
          fontSize: 12,
          fontFamily: 'verdana',
          textTransform: 'uppercase',
      },
      bloco50R: {
          width: '50%',
          textAlign: 'right',
          fontSize: 12,
          fontFamily: "Cochin",
          textTransform: 'uppercase',
      },
      row: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        padding: 0,
        borderRadius : 1,
        width: '100%',
        borderColor: 'rgba(161,155,183,1)',
        marginBottom: 10,
      },
      button: {
        borderWidth: StyleSheet.hairlineWidth,
        '&.checked': {
          backgroundColor: '#000',
        },
        borderRadius: 10
      },
      controlSpace: {
          flexDirection: 'column',
          flexWrap: 'wrap',
          backgroundColor: '#F5F5F5',
      },
      buttonView: {
          width: '100%',
          padding: 5,
      },
      text: { textAlign: 'center' },
      text1: {
          fontSize: 11,
          textTransform: 'uppercase',
      },
      text1red: {
          fontSize: 11,
          textTransform: 'uppercase',
          color: 'red'
      },
      text15: {
        fontSize: 15,
        textTransform: 'uppercase',
      },
      text15red: {
        fontSize: 15,
        textTransform: 'uppercase',
        color: 'red'
      },
      badgeAtivo: {
          backgroundColor: 'red',
          color: 'white'
      }
  });

  export default tickets;