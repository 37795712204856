import React from 'react';
import LoginScreen from '../screens/LoginScreen';
import { createStackNavigator } from '@react-navigation/stack';

const AuthStack = createStackNavigator();

const AuthRoutes: React.FC = () => (
    <AuthStack.Navigator>
        <AuthStack.Screen 
            name='Login' 
            component={LoginScreen} 
            options={
                { 
                  title: 'PALPITE',
                  headerStyle: {
                    backgroundColor: 'rgba(24, 128, 56, .4)',
                  },
                  headerTitleAlign: 'center',
                  headerTintColor: '#fff',
                  headerTitleStyle: {
                    fontWeight: 'bold',
                  },
                  // headerShown: false,
      
                }
              }
            />
    </AuthStack.Navigator> 
)

export default AuthRoutes;