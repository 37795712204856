import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, Linking, ScrollView } from 'react-native';
import Cesta from '../components/cesta';
import { useAuth } from '../contexts/auth';
import { nameValidator, whatsappValidator } from '../../core/utils';
import { Navigation } from '../../types';
import { useFocusEffect } from '@react-navigation/native';
import { Divider, HelperText, ToggleButton, Button, Badge, Card } from 'react-native-paper';
//import Button from '../components/Button';
import { general, ticketsStyle } from '../../css';
import TextInput from '../components/TextInput';
import MaskInput from 'react-native-mask-input';
import NewGameScreenPalpite from './NewGameScreenPalpite';
import { addJogo, listarConcursos } from '../services/api';
import moment from 'moment';
import QRCode from 'react-native-qrcode-svg';

type Props = {
    navigation: Navigation;
};

type Concursos = {
    id: number;
    titulo: String;
    premio_garantido: any;
    primeiro_sorteio: Date,
    percentual_10: any,
    percentual_9: any,
    percentual_8: any,
    percentual_7: any,
    percentual_6: any,
    percentual_5: any,
    percentual_0: any,
}


const formatarMoeda = (numero: Number) => "" + numero.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");


const NewGameScreenImprimir = ({ navigation }: Props) => {
    const { config, jogosGravados, concurso, signOut, jogador, jogo } = useAuth();
    const [loading, setLoading] = useState(false);
    const [concursos, setConcursos] = useState<Concursos[]>();
    const [numJogador, setNumJogador] = useState("");

    const formatarMoeda = (numero: Number) => "" + numero.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    useFocusEffect(
        React.useCallback(() => {
            // Do something when the screen is focused
            //console.log('is focused')
            
            listarConcursos().then((concurso) => {
                setConcursos(concurso.data);
                setNumJogador((jogosGravados.length>0) ? jogosGravados[0].jogo.whatsapp.replace(' ','').replace('(','').replace(')','').replace('-','') : '')
                setLoading(false);
                console.log("Buscou novos dados")
            }).catch((err) => {
                console.log(err);
                setLoading(false);
                signOut();
            }).finally(() => {
                console.log("finalizado")
            });

            return () => {
                // Do something when the screen is unfocused
                // Useful for cleanup functions
                //navigation.navigate.

            };
        }, [])
    );

    const OpenURLButton = ({ url, label, icon }) => {
        const handlePress = useCallback(async () => {
            // Checking if the link is supported for links with custom URL scheme.
            const supported = await Linking.canOpenURL(url);

            if (supported) {
                // Opening the link with some app, if the URL scheme is "http" the web link should be opened
                // by some browser in the mobile
                await Linking.openURL(url);
                //navigation.navigate('Home');

            } else {
                console.log(`Don't know how to open this URL: ${url}`);
            }
        }, [url]);

        return <Button icon={icon} onPress={handlePress} style={{backgroundColor: "green", margin:10}} textColor='white'> {label}</Button>;
    };

    const linkWhatsApp = jogosGravados.map((jogo, index) =>
        "Código:%20" + jogo.codigo + "%20%0A" + config.urlPrincipal + "/apps/resultados/preview/" + jogo.codigo + "%0A"
    );

    const tickets = jogosGravados.map((jogo, index) =>
        <View style={ticketsStyle.row} key={index}>
            <Card style={{ width: "100%" }}>
                <Card.Content>
                    <View style={ticketsStyle.emBlocos}>
                        <Text style={ticketsStyle.bloco50L}>
                            DATA/HORA
                        </Text>
                        <Text style={ticketsStyle.bloco50R}>
                            {moment(jogo.created_at).format('DD/MM/YYYY LTS')}
                        </Text>
                    </View>

                    <View style={{ flexDirection: 'row', padding: 10, borderWidth: 1, borderStyle: 'dashed' }}>
                        <Badge style={[ticketsStyle.badgeAtivo]} size={27}>{jogo.jogo.jogo[0]}</Badge>
                        <Badge style={[ticketsStyle.badgeAtivo]} size={27}>{jogo.jogo.jogo[1]}</Badge>
                        <Badge style={[ticketsStyle.badgeAtivo]} size={27}>{jogo.jogo.jogo[2]}</Badge>
                        <Badge style={[ticketsStyle.badgeAtivo]} size={27}>{jogo.jogo.jogo[3]}</Badge>
                        <Badge style={[ticketsStyle.badgeAtivo]} size={27}>{jogo.jogo.jogo[4]}</Badge>
                        <Badge style={[ticketsStyle.badgeAtivo]} size={27}>{jogo.jogo.jogo[5]}</Badge>
                        <Badge style={[ticketsStyle.badgeAtivo]} size={27}>{jogo.jogo.jogo[6]}</Badge>
                        <Badge style={[ticketsStyle.badgeAtivo]} size={27}>{jogo.jogo.jogo[7]}</Badge>
                        <Badge style={[ticketsStyle.badgeAtivo]} size={27}>{jogo.jogo.jogo[8]}</Badge>
                        <Badge style={[ticketsStyle.badgeAtivo]} size={27}>{jogo.jogo.jogo[9]}</Badge>
                    </View>



                    <View style={ticketsStyle.emLinhas}>
                        <Text style={ticketsStyle.text1}>CÓDIGO DO PALPITE: {jogo.codigo}</Text>
                    </View>

                </Card.Content>
            </Card>
        </View>
    );

    return (
        <ScrollView style={{padding:10}} showsVerticalScrollIndicator={false}>
                <View style={[ticketsStyle.controlSpace]}>
                    <View style={ticketsStyle.row} >
                        <Card style={{ width: "100%" }}>
                            <Card.Content style={{marginBottom:10, marginTop: 10, paddingBottom:5, paddingTop: 5}}>
                                <View style={ticketsStyle.emLinhas}>
                                    <Text style={ticketsStyle.text15red}>SENINHA - {concurso?.titulo}</Text>
                                    <Text style={ticketsStyle.text1red}>PALPITE PENDENTE DE PAGAMENTO</Text>
                                    <Text style={ticketsStyle.text15red}>PROCURE O OPERADOR OU CLIQUE NO BOTÃO ABAIXO:</Text>
                                    <OpenURLButton icon={"whatsapp"} label="VALIDAR JOGO" url={`https://api.whatsapp.com/send?phone=${jogosGravados[0].user.celular}&text=Olá%20🍀.%0AGostaria%20de%20ativar%20o(s)%20palpite(s)%20%0A${linkWhatsApp.join("%20%0A")}`} />
                                    <Text style={ticketsStyle.text15red}>NA FALTA DE PAGAMENTO, ESTE PALPITE SERÁ EXCLUÍDO EM ATÉ 24H ANTES DO PRIMEIRO SORTEIO</Text>
                                    <Text style={ticketsStyle.text15}>TOTAL DA APOSTA: R${(jogo ? formatarMoeda(jogo.length * concurso.valor_aposta) : '0').toString()}</Text>
                                </View>

                            </Card.Content>
                        </Card>
                    </View>
                </View>
                <View style={[ticketsStyle.controlSpace]}>
                    {tickets}
                </View>
                <View style={[ticketsStyle.controlSpace]}>
                    <View style={ticketsStyle.row} >
                        <Card style={{ width: "100%" }}>
                            <Card.Content>
                                <View style={ticketsStyle.emLinhas}>
                                    <Text style={ticketsStyle.text15}>ATENÇÃO</Text>
                                    <Text style={ticketsStyle.text1}>SEU PALPITE FOI REGISTRADO COM O STATUS:</Text>
                                    <Text style={ticketsStyle.text15}>PENDENTE DE PAGAMETNO</Text>
                                </View>

                            </Card.Content>
                        </Card>
                    </View>
                </View>
        </ScrollView>

    );
}

export default NewGameScreenImprimir;
