import {StyleSheet} from 'react-native';
import { blue100 } from 'react-native-paper/lib/typescript/styles/colors';

const general = StyleSheet.create({
    container: {
      flexDirection: 'column', 
      width: '100%',
      padding: 2,
      
    },
    containerMiddle: {
        flexDirection: 'row', 
        display: 'flex',
        backgroundColor: 'white',
        width: '100%',
        padding: 2,
        alignItems: 'stretch',
        justifyContent: 'space-between',
      },
    containerCartao: {
        flexDirection: 'column', 
        width: '100%',
        padding: 2,
        backgroundColor: '#FFF',
        borderRadius: 10,
      },
    header: {
        flexDirection: 'row',
        flex: 1,  
        alignItems: 'center',
        backgroundColor: '#00bc8c', 
        padding: 2
        
    },
    headerContent: {
        flexDirection: 'column',
        flex: 1,  
        width: '50%',
        alignItems: 'flex-start',
        padding: 2,
    },
    headerContentRight: {
        flexDirection: 'column',
        flex: 1,  
        width: '50%', 
        alignItems: 'flex-end',
        padding: 2,
        textDecorationColor: 'uppercase',
        
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      padding: 0,
    },
    button: {
      borderWidth: 0.3,
      borderRadius: 5,
      width: '10%' 
      
    },
    first: {
        borderRadius: 5,
    },
    middle: {
      borderRadius: 5,
      borderLeftWidth: 0,
    },
    last: {
        borderRadius: 5,
    },
    cardHeader: {
        backgroundColor: '#00bc8c',
        color: '#ffffff'
      },
    playingSpace: {
        backgroundColor: 'white',
        borderColor: 'blue',
        borderWidth: 3,
    },
    controlSpace: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1, alignItems: 'center', justifyContent: 'center',
    },
    buttonView: {
        width: '50%',
        padding: 2,
        paddingTop: 10,
        paddingBottom: 10,
        
    },
    buttonView3: {
        width: '33%',
        padding: 2,
    },
    buttonViewFull: {
        width: '100%',
        padding: 2,
        paddingTop: 10,
        paddingBottom: 10,
    },
    text: { textAlign: 'center' },
    chip: {
        padding: 0,
        margin: 0,
        
    },
    radioGroupFull: {
        backgroundColor: '#f7f7f7',
        marginTop: 2,
        borderRadius: 10,
    },
    
    emLinhas: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    emBlocos: {
        flex: 2,
        flexDirection: 'row',
        minWidth: '50%',
        flexWrap: "nowrap",
    },
    cardConcursosFull: {
        flexDirection: 'row',
        flex: 1
    },
    cardConcursos: {
        flexDirection: 'column',
        minWidth: '100%',
        marginTop: 5,
        borderStartColor: '#000',
        backgroundColor: '#F39200',
    },
    tarjaTitle: {
        textAlign: 'center', 
        color: '#12187c',
        textTransform: 'uppercase',
        fontSize: 15,
        fontWeight: 'bold',
        margin: 0,
    },
    bloco50L: {
        flexDirection: 'column',
        minWidth: '50%',
        textAlign: 'left',
        fontSize: 12,
        fontFamily: 'verdana',
        textTransform: 'uppercase',
    },
    bloco50R: {
        flexDirection: 'column',
        minWidth: '50%',
        textAlign: 'right',
        fontSize: 12,
        fontFamily: "Cochin",
        textTransform: 'uppercase',
    },
    cardConcursosBloco50L: {
        flexDirection: 'column',
        minWidth: '50%',
        textAlign: 'left',
        fontSize: 18,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    cardConcursosBloco50R: {
        flexDirection: 'column',
        minWidth: '50%',
        textAlign: 'right',
        fontSize: 18,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    text1: {
        fontSize: 12,
        textTransform: 'uppercase',
    },
    text18: {
        fontSize: 18,
        textTransform: 'uppercase'
    },
    toggleSelected: {
        color: '#FFFFFF',
        borderWidth: 0,
        borderRadius: 10,
        width: '10%',
        backgroundColor: 'green'
    },
    toggle: {
        color: '#000',
        borderWidth: 0.3,
        borderRadius: 5,
        width: '10%',
      },
    toggleTextSelected: {
        color: '#FFFFFF',
    },
    toggleText: {
        color: 'green',
    },
    textInput: {
        //borderWidth: 1,   
        alignSelf: "center",
        borderColor: 'gray',
        padding: 10,
        borderRadius: 5,
        width: '100%',
        marginBottom: 10,
        color: '#777',
        backgroundColor: "#FFF",
        
    },
    textInputWA: {
        //borderWidth: 1,   
        alignSelf: "center",
        borderColor: 'gray',
        padding: 20,
        borderRadius: 5,
        width: '100%',
        marginBottom: 10,
        color: '#444',
        backgroundColor: "#FFF",
        fontSize: 18
    }

  });

  export default general;