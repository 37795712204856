import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Cesta from '../components/cesta';
import { useAuth } from '../contexts/auth';
import { nameValidator, whatsappValidator } from '../../core/utils';
import { Navigation } from '../../types';
import { StackActions, useFocusEffect } from '@react-navigation/native';
import { HelperText } from 'react-native-paper';
import Button from '../components/Button';
import { general } from '../../css';
import TextInput from '../components/TextInput';
import MaskInput from 'react-native-mask-input';
import { ScrollView } from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';

type Props = {
  navigation: Navigation;
};

const NewGameScreen = ({ navigation }: Props) => {
  const [nome, setNome] = React.useState({ value: '', error: '' });
  const [whatsapp, setWhatsapp] = React.useState({ value: '', error: '' });
  const { signOut, user, jogador, jogo, addJogador, limpaJogo, concurso, limpaJogador, limpaJogosGravados } = useAuth();

  const _onFormPressed = () => {
    const nomeError = nameValidator(nome.value);
    const whatsappError = whatsappValidator(whatsapp.value);

    if (nomeError || whatsappError) {
      setNome({ ...nome, error: nomeError });
      setWhatsapp({ ...whatsapp, error: whatsappError });
      return;
    }

    const novoJogador = { nome: nome.value, whatsapp: whatsapp.value };

    limpaJogo();
    addJogador(novoJogador);

    navigation.navigate('NewGameScreenPalpite')

  };

  useFocusEffect(
    React.useCallback(() => {
      // Do something when the screen is focused
      //console.log('is focused')
      limpaJogador();
      limpaJogo();
      limpaJogosGravados();
      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions  

      };
    }, [])
  );

  const hasErrors = () => {
    if (whatsapp.error.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const phoneMask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  return (
    <View
      style={
        {
          flex: 1,
          padding: 2,
          flexDirection: 'column'
        }
      }>
      <Cesta
        jogador={jogador?.nome}
        concurso={(concurso ? concurso.titulo : '---').toString()}
        quantidadeJogo={(jogo ? jogo.length : '0').toString()}
        valor={(jogo ? jogo.length * concurso.valor_aposta : '0').toString()}
        whatsapp={jogador?.whatsapp}
      />
      <ScrollView style={{padding:5}} showsVerticalScrollIndicator={false}>
        <View style={
          {
            padding: 5
          }
        }>

          <TextInput
            style={[general.textInput]}
            label="Nome do Jogador"
            returnKeyType="next"
            value={nome.value}
            onChangeText={text => setNome({ value: text.toUpperCase(), error: '' })}
            error={!!nome.error}
            errorText={nome.error}
            autoCapitalize={"characters"}
            textContentType="name"
            keyboardType="default"
            maxLength={10}
          />

          <MaskInput
            style={[general.textInputWA]}
            value={whatsapp.value}
            onChangeText={(masked, unmasked) => {
              setWhatsapp({ value: masked, error: '' })
            }
            }
            mask={phoneMask}
            returnKeyType="next"
            autoCapitalize="none"
            textContentType="telephoneNumber"
            keyboardType="phone-pad"
          />
          <HelperText type="error" visible={hasErrors()} style={{ backgroundColor: 'white', width: '100%', marginTop: 5 }}>
            {whatsapp.error}
          </HelperText>


          <TouchableOpacity
            style={{
              backgroundColor: 'green',
              padding: 10,
              borderRadius: 5,
              width: '100%',
              alignItems: 'center',
            }}
            onPress={_onFormPressed}
          >
            <Ionicons name="grid-outline" size={24} color="white" /><Text style={{ color: 'white' }}>DÊ O SEU PALPITE</Text>
          </TouchableOpacity>

        </View>
      </ScrollView>
    </View>
  );
}

export default NewGameScreen;
