import React, { useEffect, useState } from 'react';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StyleSheet, View, ImageBackground, Image } from 'react-native';

import Routes from './src/routes';

import {AuthProvider} from './src/contexts/auth';

const Stack = createStackNavigator();

export default function App() {
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const checkToken = async () => {
    const storedToken = await AsyncStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    checkToken();
  }, []);

  if (isLoading) {
    return null; // Aqui poderia ter um componente de loading mais elaborado, mas para fins de simplicidade estou retornando null
  }

  const MyTheme = {
    dark: false,
    colors: {
      primary: 'rgb(255, 45, 85)',
      background: 'transparent',
      card: 'rgb(255, 255, 255)',
      text: 'rgb(28, 28, 30)',
      border: 'rgb(199, 199, 204)',
      notification: 'rgb(255, 69, 58)',
    },
  };

  return (
    <ImageBackground source={require('./assets/background_v5.png')} style={styles.backgroundImage}>
      <NavigationContainer theme={MyTheme}>
        <AuthProvider>
          <Routes/>
        </AuthProvider>
      </NavigationContainer>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundImage: {
    flex: 1,
    resizeMode: 'cover',
  },
});
