import React, { createContext, useState, useEffect, useContext } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import http, {createSession, createSessionOperador} from '../services/api';
//import * as auth from '../services/auth'

import { Jogador, Jogo, Concurso } from '../../types';

interface User {
    id: BigInt;
    name: String;
} 

interface AuthContextData {
    signed: boolean;
    codigo: String;
    addJogador(Jogadaor: Jogador): void;
    limpaJogador(): void;
    addJogo(Jogo: Jogo): void;
    limpaJogo(): void;
    concurso: Concurso | null;
    addConcurso(Concurso: Concurso): void;
    jogosGravados: [];
    addJogoGravado(JogosGravados: []): void;
    limpaJogosGravados(): void;
    jogo: [];
    jogador: Jogador | null;
    operador: User | null;
    loading: Boolean;
    signIn(): Promise<void>;
    setIdOperador(codigo: String): Promise<void>;
    signOut(): void;
    setLoadingTrue(): void;
    setLoadingFalse(): void;
    config: {
        urlPrincipal: "https://ticket.seninha.bet",
        urlApi: "https://api.seninha.bet",
        opderadorDefault: "",
    }
}


const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({children}) => {
    const [operador, setOperador] = useState<User | null>(null);
    const [jogador, setJogador] = useState<Jogador | null>(null);
    const [concurso, setConcurso] = useState<Concurso | null>(null);
    const [jogo, setJogo] = useState<Jogo | null>(null);
    const [loading, setLoading] = useState(true);
    const [jogosGravados, setJogosGravados] = useState<any[]>([]);
    const [codigo, setCodigo] = useState<String | 'null'>('');

    useEffect(() => {
        async function loadStorageData() {
            const storageOperador = await AsyncStorage.getItem('@RNAuth:nome');
            if (storageOperador){
                setOperador(JSON.parse(storageOperador));
            }
            setLoading(false);
        }
        loadStorageData();
    }, []);

    async function signIn(email, password){
        setLoading(true);
        try {
            
            const response = await createSession(email, password); 
            //setUser(response.data.userData);
            http.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
            await AsyncStorage.setItem('@RNAuth:user', JSON.stringify(response.data.userData));
            await AsyncStorage.setItem('@RNAuth:token', response.data.accessToken);
            
        } catch (error) {
            console.error(error);           
        }
        setLoading(false);
    } 

    async function setIdOperador(codigo: String){
        setLoading(true);
        try {
            
            const response = await createSessionOperador(codigo); 
            setOperador(response.data);
            setCodigo(codigo);
            await AsyncStorage.setItem('@RNAuth:codigo', JSON.stringify(codigo));
            await AsyncStorage.setItem('@RNAuth:nome', JSON.stringify(response.data.login));
            //await AsyncStorage.setItem('@RNAuth:token', response.data.accessToken);
            
        } catch (error) {
            console.error(error);     
            await AsyncStorage.clear();      
        }
        setLoading(false);
    } 

    function signOut(){
        AsyncStorage.clear().then(() => {
            setUser(null);
        });
    } 

    function addJogador(Jogador: Jogador){
        setJogador(Jogador);
    }
    function limpaJogador () {
        setJogador([]);
    }

    


    function addConcurso(Concurso: Concurso){
        setConcurso(Concurso); 
    }

    /* JOGOS */
    function addJogo(Jogo: Jogo){
        jogo.push(Jogo); 
    }
    function limpaJogo () {
        setJogo([]);
    }


    /* JOGOS GRAVADOS */
    function addJogoGravado(id: any){
        //setJogosGravado(id);
        setJogosGravados(prevArray => [...prevArray, id]);
        //jogosGravados.push(id);
    }
    function limpaJogosGravados () {
        setJogosGravados([]);
    }



    function setLoadingFalse() {
        setLoading(false);
    }

    function setLoadingTrue() {
        setLoading(true);
    }


    return (
        <AuthContext.Provider value={{
                signed: !!operador, 
                operador, 
                codigo,
                setIdOperador,
                signIn, 
                signOut, 
                loading, 
                jogo, 
                jogador, 
                addJogador, 
                limpaJogador,
                concurso,
                addConcurso,
                addJogo,
                limpaJogo,
                setLoadingTrue,
                setLoadingFalse,
                addJogoGravado,
                jogosGravados,
                limpaJogosGravados,
                config: {
                    urlPrincipal: "https://www.seninha.bet",
                    urlApi: "https://api.seninha.bet",
                }
            }}>
            {children}
        </AuthContext.Provider>
    );

}

export function useAuth() {
    const context = useContext(AuthContext);
    return context;
}