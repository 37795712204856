import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';

const api = axios.create({
  baseURL: 'https://api.seninha.bet/api/',
  //baseURL: 'http://localhost/bms-api/public/api/',
});


api.interceptors.request.use(async (config) => {
  try {
    const token = await AsyncStorage.getItem('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  } catch (error) {
    console.log(error);
  }
});


export const createSession = async (login: email, password) => {
  const crypt = 'AJ7PDhO8ya5';
  return api.post("/login", {login, password, crypt});
};

export const createSessionOperador = async (codigo) => {
  return api.get("/buscaOperadorPorCod" + codigo);
};

export const getUserDetail = async () => { 
  return api.get("/get_user");
};

export const listarJogos = async () => { 
  return api.get("/jogos");
};

export const listarConcursos = async () => { 
  return api.get("/listarConcursos");
};

export function getConcursos<T = unknown>(){
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
      api.get("/concursos")
      .then(response => {
          setData(response.data);
          setLoading(false);
      })
      .catch(err => {
          console.log(err)
          setError(err.response.data);
          setLoading(false);
      }) 
      
  }, []);

  return {data, error, loading}; 
}

export function getJogos<T = unknown>(){
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
      api.get("/jogos")
      .then(response => {
          setData(response.data);
          setLoading(false);
      })
      .catch(err => {
          setError(err.response.data);
          setLoading(false);
      })
      
  }, []);

  return {data, error, loading}; 
}


export function getJogo<T = unknown>(id: any){
  return api.get(`/jogos/${id}`);
}


export function meusJogosByConcurso<T = unknown>(id: any){
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
      api.get(`/meusJogosByConcurso/${id}`)
      .then(response => {
          setData(response.data);
      })
      .catch(err => {
          setError(err.response.data);
      }) 
      
  }, []);


  return {data, error, loading}; 
}

export function getRanking<T = unknown>(id: any){
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
      api.get(`/ranking/${id}`)
      .then(response => {
          setData(response.data);
      })
      .catch(err => {
          setError(err.response.data);
      }) 
      
  }, []);

  return {data, error, loading}; 
}


export function getPremio0<T = unknown>(id: any){
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
      api.get(`/premio0/${id}`)
      .then(response => {
          setData(response.data);
      })
      .catch(err => {
          setError(err.response.data);
      }) 
      
  }, []);

  return {data, error, loading}; 
}


export function getPremio5<T = unknown>(id: any){
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
      api.get(`/premio5/${id}`)
      .then(response => {
          setData(response.data);
      })
      .catch(err => {
          setError(err.response.data);
      }) 
      
  }, []);

  return {data, error, loading}; 
}

export function getPremio6<T = unknown>(id: any){
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
      api.get(`/premio6/${id}`)
      .then(response => {
          setData(response.data);
      })
      .catch(err => {
          setError(err.response.data);
      }) 
      
  }, []);

  return {data, error}; 
}

export function getPremio7<T = unknown>(id: any){
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
      api.get(`/premio7/${id}`)
      .then(response => {
          setData(response.data);
      })
      .catch(err => {
          setError(err.response.data);
      }) 
      
  }, []);

  return {data, error}; 
}

export function getPremio8<T = unknown>(id: any){
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
      api.get(`/premio8/${id}`)
      .then(response => {
          setData(response.data);
      })
      .catch(err => {
          setError(err.response.data);
      }) 
      
  }, []);

  return {data, error}; 
}

export function getPremio9<T = unknown>(id: any){
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
      api.get(`/premio9/${id}`)
      .then(response => {
          setData(response.data);
      })
      .catch(err => {
          setError(err.response.data);
      }) 
      
  }, []);

  return {data, error}; 
}

export function getPremio10<T = unknown>(id: any){
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
      api.get(`/premio10/${id}`)
      .then(response => {
          setData(response.data);
      })
      .catch(err => {
          setError(err.response.data);
      }) 
      
  }, []);

  return {data, error}; 
}


export const addJogo = async (concurso_id: Number | undefined, data: never, codOperador: string) => { 
  //console.log("AQUI: ", concurso_id && " / " && data && " / " && codOperador);
  const ip = await axios.get("https://api.ipify.org/?format=json");
  return api.post("/gravarPalpitePublico", {concurso_id, data, codOperador: codOperador, ip_aposta: ip.data});
};

export default api;
