import React, { memo } from 'react';
import { Image, StyleSheet } from 'react-native';

const Logo = () => (
  <Image source={require('../../assets/logo.png')} style={styles.image} />
);

const styles = StyleSheet.create({
  image: {
    width: 270,
    height: 170,
    marginBottom: 30,
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
});

export default memo(Logo);
