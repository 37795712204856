export const emailValidator = (email: string) => {
    //const re = /\S+@\S+\.\S+/;
  
    //if (!email || email.length <= 0) return 'Email não pode ser vazio.';
    //if (!re.test(email)) return 'Ooops! precisamos de um e-mail válido.';
  
    return '';
  };
  
  export const passwordValidator = (password: string) => {
    if (!password || password.length <= 0) return 'Digite a senha.';
  
    return '';
  };
  
  export const nameValidator = (name: string) => {
    if (!name || name.length <= 0) return 'Nome não pode ser vazio.';
  
    return '';
  };
  
  export const whatsappValidator = (name: string) => {
    if (!name || name.length <= 0) return 'WhatsApp não pode ser vazio.';
    if (!name || name.length < 15) return 'AhatsApp deve conter 9 dígitos. Coloque o 0 (ZERO) se necessário (11) 0 xxxx.xxxx';
  
    return '';
  };
  