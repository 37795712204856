import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useAuth } from '../contexts/auth';
import { Ionicons } from '@expo/vector-icons';
import Logo from '../components/Logo';


const LoginScreen = () => {
  const { operador } = useAuth();

  
  useEffect(() => {
    console.log(operador);
  }, [operador]); // Only re-run the effect if count changes


  const setarOperadorPadrao = () => {
    try {
      //const response = await api.post('/login', { login, password });
      //const {accessToken} = response.data;
      //signIn(login, password);

      //await AsyncStorage.setItem('token', accessToken);
      //navigation.navigate('Home');
      //navigation.navigate('Home', { screen: 'Home' });

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View style={{ flex: 1, alignItems: 'center', paddingTop: 10 }}>
      <Logo />
      

      <TouchableOpacity
        style={{
          backgroundColor: 'green',
          padding: 10,
          borderRadius: 5,
          width: '90%',
          alignItems: 'center',
        }}
        onPress={setarOperadorPadrao}
      >
        <Ionicons name="body" size={24} color="white" /><Text style={{ color: 'white' }}>Operador Não Encontrado</Text>
      </TouchableOpacity>
    </View>
  );
};


export default LoginScreen;
