import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator } from 'react-native';

import { useAuth } from '../contexts/auth';

import AuthRoutes from './auth.routes';
import AppRoutes from './app.routes';



const Routes: React.FC = () => {
    const { signed, loading, operador } = useAuth();
    const { setIdOperador } = useAuth();
    const [codigo, setCodigo] = useState('');

    
    useEffect(() => {
        setCodigo(window.location.pathname);
        setIdOperador(window.location.pathname);
      }, [codigo]); // Only re-run the effect if count changes

    if (loading) {
        return (
            <View>
                <ActivityIndicator size='large' color='#777' />
            </View>
        );
    }

    return signed ? <AppRoutes /> : <AuthRoutes />;
}

export default Routes;