import React, { useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import Cesta from '../components/cesta';
import { useAuth } from '../contexts/auth';
import { Navigation } from '../../types';
import { ToggleButton, Button } from 'react-native-paper';
import { general } from '../../css';
import { useFocusEffect } from '@react-navigation/native';

type Props = {
    navigation: Navigation;
};

const NewGameScreenPalpite = ({ navigation }: Props) => {
    const [selectedButtons, setSelectedButtons] = useState([]);
    const { jogador, addJogo, jogo, concurso, limpaJogosGravados } = useAuth();
    const [loading, setLoading] = useState(false);


    const onButtonToggle = (value) => {
        if (isSelected(value) === 'checked') {
            setSelectedButtons(selectedButtons.filter((i) => i !== value));
        } else {
            if (selectedButtons.length > 9) {
                return;
            }
            setSelectedButtons([...selectedButtons, value]);
        }
    }

    const isSelected = (value) =>
        selectedButtons.includes(value) ? 'checked' : 'unchecked';



    const adicionarJogo = () => {
        const numAscending = [...selectedButtons].sort((a, b) => a - b);
        addJogo(numAscending);
        limparSelecao();
    };


    const limparSelecao = () => {
        setSelectedButtons([]);
    };



    const criarJogoAleatorio = () => {
        setLoading(true);
        limparSelecao();

        var arr = [];
        while (arr.length < 10) {
            var r = Math.floor(Math.random() * 60) + 1;
            if (r < 10) { r = "0" + r; } else { r = "" + r }
            if (arr.indexOf(r) === -1) {
                arr.push(r);
            }
        }

        setTimeout(() => {
            const numAscending = [...arr].sort((a, b) => a - b);
            setSelectedButtons(numAscending);
            setLoading(false);
        }, 50);


    }

    const ToggleButtonBMS = (props: { label: string | undefined; }) => {
        return (
            <ToggleButton value={props.label} icon={() =>
                <View>
                    <Text style={[(isSelected(props.label) == 'unchecked') ? general.toggleText : general.toggleTextSelected]}>{props.label}</Text>
                </View>
            } style={[(isSelected(props.label) == 'unchecked') ? general.toggle : general.toggleSelected]} status={isSelected(props.label)} onPress={() => onButtonToggle(props.label)} />
        );
    }


    useFocusEffect(
        React.useCallback(() => {
          // Do something when the screen is focused
          //console.log('is focused')
          limpaJogosGravados();
          return () => {
            // Do something when the screen is unfocused
            // Useful for cleanup functions  
    
          };
        }, [])
      );

    return (
        <View
            style={{
                flex: 1,
                padding: 2, flexDirection: 'column'
            }}>
            <Cesta
                jogador={jogador?.nome}
                concurso={(concurso ? concurso.titulo : '---').toString()}
                quantidadeJogo={(jogo ? jogo.length : '0').toString()}
                valor={(jogo ? jogo.length * concurso.valor_aposta : '0').toString()}
                whatsapp={jogador?.whatsapp}
            />

            <ScrollView style={{padding:5}} showsVerticalScrollIndicator={false}>
                <View style={{
                    flexDirection: 'row'
                }}>
                    <View style={general.buttonView}>
                        <Button
                            onPress={() => limparSelecao()}
                            style={{ backgroundColor: 'green' }}
                            textColor='#FFF'
                            icon={"eraser"}
                        >LIMPAR</Button>
                    </View>
                    <View style={general.buttonView}>
                        <Button
                            onPress={() => criarJogoAleatorio()}
                            style={{ backgroundColor: 'green' }}
                            textColor='#FFF'
                            icon={"lightbulb-on-outline"}
                        > SUPRESINHA</Button>
                    </View>

                </View>


                <View style={[general.containerCartao]}>
                    <View style={general.row} >
                        <ToggleButtonBMS label='01' />
                        <ToggleButtonBMS label='02' />
                        <ToggleButtonBMS label='03' />
                        <ToggleButtonBMS label='04' />
                        <ToggleButtonBMS label='05' />
                        <ToggleButtonBMS label='06' />
                        <ToggleButtonBMS label='07' />
                        <ToggleButtonBMS label='08' />
                        <ToggleButtonBMS label='09' />
                        <ToggleButtonBMS label='10' />
                    </View>
                    <View style={general.row}>
                        <ToggleButtonBMS label='11' />
                        <ToggleButtonBMS label='12' />
                        <ToggleButtonBMS label='13' />
                        <ToggleButtonBMS label='14' />
                        <ToggleButtonBMS label='15' />
                        <ToggleButtonBMS label='16' />
                        <ToggleButtonBMS label='17' />
                        <ToggleButtonBMS label='18' />
                        <ToggleButtonBMS label='19' />
                        <ToggleButtonBMS label='20' />
                    </View>
                    <View style={general.row}>
                        <ToggleButtonBMS label='21' />
                        <ToggleButtonBMS label='22' />
                        <ToggleButtonBMS label='23' />
                        <ToggleButtonBMS label='24' />
                        <ToggleButtonBMS label='25' />
                        <ToggleButtonBMS label='26' />
                        <ToggleButtonBMS label='27' />
                        <ToggleButtonBMS label='28' />
                        <ToggleButtonBMS label='29' />
                        <ToggleButtonBMS label='30' />
                    </View>
                    <View style={general.row}>
                        <ToggleButtonBMS label='31' />
                        <ToggleButtonBMS label='32' />
                        <ToggleButtonBMS label='33' />
                        <ToggleButtonBMS label='34' />
                        <ToggleButtonBMS label='35' />
                        <ToggleButtonBMS label='36' />
                        <ToggleButtonBMS label='37' />
                        <ToggleButtonBMS label='38' />
                        <ToggleButtonBMS label='39' />
                        <ToggleButtonBMS label='40' />
                    </View>
                    <View style={general.row}>
                        <ToggleButtonBMS label='41' />
                        <ToggleButtonBMS label='42' />
                        <ToggleButtonBMS label='43' />
                        <ToggleButtonBMS label='44' />
                        <ToggleButtonBMS label='45' />
                        <ToggleButtonBMS label='46' />
                        <ToggleButtonBMS label='47' />
                        <ToggleButtonBMS label='48' />
                        <ToggleButtonBMS label='49' />
                        <ToggleButtonBMS label='50' />
                    </View>
                    <View style={general.row}>
                        <ToggleButtonBMS label='51' />
                        <ToggleButtonBMS label='52' />
                        <ToggleButtonBMS label='53' />
                        <ToggleButtonBMS label='54' />
                        <ToggleButtonBMS label='55' />
                        <ToggleButtonBMS label='56' />
                        <ToggleButtonBMS label='57' />
                        <ToggleButtonBMS label='58' />
                        <ToggleButtonBMS label='59' />
                        <ToggleButtonBMS label='60' />
                    </View>

                </View>


                <View style={[general.controlSpace]}>

                    <View style={general.buttonViewFull}>
                        <Button
                            onPress={() => adicionarJogo()}
                            disabled={selectedButtons.length > 9 ? false : true}
                            style={{ backgroundColor: 'green' }}
                            textColor='#FFF'
                            icon={"plus-circle-outline"}
                        > INCLUIR PALPITE </Button>
                    </View>


                    <View style={general.buttonViewFull}>
                        <Button
                            onPress={() => navigation.navigate('NewGameScreenConferir')}
                            disabled={((jogo.length > 0) && (selectedButtons.length < 1)) ? false : true}
                            style={{ backgroundColor: 'green' }}
                            textColor='#FFF'
                            icon={"eye-circle-outline"}
                        > CONFERIR PALPITE </Button>


                    </View>
                </View>
            </ScrollView>
        </View>
    );
}

export default NewGameScreenPalpite;

