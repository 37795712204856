import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, ImageBackground, Linking, ScrollView } from 'react-native';
import { useAuth } from '../contexts/auth';
import Cesta from '../components/cesta';
import { useFocusEffect } from '@react-navigation/native';
import { listarConcursos } from '../services/api';
import { Avatar, Card, Button } from 'react-native-paper';
import { general } from '../../css';
import moment from 'moment';
import { Navigation } from '../../types';



type Concursos = {
  data: {
      id: number;
      titulo: String;
      premio_garantido: any; 
      primeiro_sorteio: Date,
      percentual_10: any,
      percentual_9: any,
      percentual_8: any,
      percentual_7: any,
      percentual_6: any,
      percentual_5: any,
      percentual_0: any,
  }
}

type Props = {
  navigation: Navigation; 
};

const HomeScreen = ({ navigation }: Props) => {
  const { addConcurso, signOut, jogador, jogo, operador, addJogador, limpaJogo, concurso, limpaJogador, limpaJogosGravados } = useAuth();
  const [concursos, setConcursos] = useState<Concursos[]>();
  
  useFocusEffect(
    React.useCallback(() => {
      // Do something when the screen is focused
      //console.log('is focused')
      limpaJogador();
      limpaJogo();
      limpaJogosGravados();
      
      listarConcursos().then((concurso) => {
            setConcursos(concurso.data);
            //setLoading(false);
            //console.log("Buscou novos dados")
        }).catch((err) => {
            console.log(err);
            //setLoading(false);
            signOut();
        }).finally(() => {
            
        });

      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [])
  );

  const OpenURLButton = ({ url, label, icon }) => {
      const handlePress = useCallback(async () => {
          const supported = await Linking.canOpenURL(url);
          if (supported) {
            await Linking.openURL(url);
         } else {
              console.log(`Don't know how to open this URL: ${url}`);
          }
      }, [url]);

      return <Button 
                onPress={handlePress} 
                mode="outlined" 
                icon={icon}
                style={{margin:5, backgroundColor: "yellow"}}
                > 
                {label}
                </Button>;
  };

  const formatarMoeda = (numero: Number) => "R$ " + numero.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const listaConcursos = concursos?.data.map( (concurso) =>  
        {
            //if (concurso.status!=0) {
                return <Card style={[general.cardConcursos]} key={concurso.id} >
                    <ImageBackground source={require('../../assets/header-bms-v1.png')} resizeMode="cover">
                    <Card.Content>
                        
                        <Card.Title 
                            title={concurso.titulo}
                            subtitle={(concurso.trava==1) ? 'FINALIZADO' : (concurso.status==1) ? 'ABERTO PARA JOGAR' : 'SORTEIO EM ANDAMENTO' }
                            left={(props) => <Avatar.Icon {...props} 
                                                        style={(concurso.trava==1) ? {backgroundColor: "red"} : (concurso.status==1) ? {backgroundColor: "green"} : {backgroundColor: "yellow"} } 
                                                        icon={(concurso.trava==1) ? "close" : (concurso.status==1) ? "check" : "exclamation" } 
                                                        />}
                        />
                        <Text style={[general.tarjaTitle]}>
                            INÍCIO: 1º SORTEIO {moment(concurso?.primeiro_sorteio).format('DD/MM/YYYY')}
                        </Text>
                        <Text style={[general.tarjaTitle]}>
                            Prêmio: {formatarMoeda(concurso.premio_garantido)}
                        </Text>
                        <Text style={[general.tarjaTitle]}>
                            Valor da Aposta: {formatarMoeda(concurso.valor_aposta)}
                        </Text>
                        {
                            (concurso.status==1 && concurso.trava!=1)
                            ?
                                <>
                                    <Button 
                                      onPress={() => {
                                        _onButtonNext(concurso);
                                      }}
                                      mode="outlined"
                                      icon={"plus-circle"}
                                      style={{margin:5, backgroundColor: "green"}}
                                      textColor='#FFFFFF'
                                      > PALPITE </Button>
                                </>
                            :
                            null
                        }
            
                    </Card.Content>


                    </ImageBackground>
                </Card>
            //}
        }
        
    );
        

    const _onButtonNext = (concurso: Concursos) => {
        addConcurso(concurso)
        navigation.navigate('NewGameScreenJogador')
    };

  return (
    <View
      style={{flex: 1,
        padding: 2,flexDirection: 'column'}}>
      <Cesta 
                jogador={jogador?.nome} 
                concurso={(concurso ? concurso.titulo : '---').toString()}
                quantidadeJogo={ (jogo ? jogo.length : '0').toString() }
                valor={(jogo ? jogo.length * 50 : '0').toString()}
                whatsapp={jogador?.whatsapp} 
                /> 
        <ScrollView style={{padding:5}} showsVerticalScrollIndicator={false}> 
          <View>
          {listaConcursos}
          </View>
        </ScrollView>
          
    </View>
  );
}

export default HomeScreen;
