import React, { useEffect } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { useAuth } from '../contexts/auth';
import { useFocusEffect } from '@react-navigation/native';
import {Card, Text} from 'react-native-paper'
import { ticketsStyle } from '../../css';

const MenuScreen = ({ navigation, route }) => {
  const { signOut } = useAuth();
  const { redirectHome } = route.params;

  useFocusEffect(
    React.useCallback(() => {
      // Do something when the screen is focused
      //console.log('is focused')
      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
           
      };
    }, [])
  );

  
  return (
    <ScrollView style={{padding:10}} showsVerticalScrollIndicator={false}>
                <View style={[ticketsStyle.controlSpace]}>

                    <View style={ticketsStyle.row} >
                        <Card style={{ width: "100%" }}>
                            <Card.Content>
                                <View style={ticketsStyle.emLinhas}>
                                  <Text variant="headlineSmall">Primeiro... vamos explicar como jogar!</Text>

                                  <Text variant="bodyLarge">Seu PALPITE pode ser feito por aqui mesmo, clicando em Página Inicial e escolhendo o CONCURSO</Text>

                                  <Text variant="bodyLarge">Você deverá fornecer um NOME ou APELIDO e um número de CELULAR de preferência que utilize para WhatsApp</Text>
                                  <Text variant="bodyLarge">Após clicar no botão 'DÊ SEU APLPITE', você deverá escolher dez números entre 01 e 50.</Text>

                                  <Text variant="bodyLarge">A cada 10 números escolhidos, você deve clicar em "INCLUIR PALPITE"</Text>

                                  <Text variant="bodyLarge">Perceba que há uma "cesta de jogos" na parte superior indicando quantos jogos e o valor total da aposta</Text>
                                 
                                  <Text variant="bodyLarge">Após incluir os palpites, clique no botão "CONFEIR PALPITE"</Text>

                                  <Text variant="bodyLarge">Confira todos os seus palpites e clique em "CONFIRMAR E GERAR PALPITE"</Text>

                                  <Text variant="bodyLarge">PARABÉNS, seus tickets foram gerados. Agora basta clicar no botão contendo o número do WhatsApp</Text>

                                  <Text variant="bodyLarge">Envie para o OPERADOR toda a mensagem contida no WhatsApp, ele enviará detalhes de como efetivar o pagamento</Text>

                                  <Text variant="bodyLarge">Na mensagem enviada, terá todos os links dos bilhete online, ele encontrará todas as informações necessárias para acompanhamento dos sorteios e acertos, além de poder ver a tabela completa organizada por posições de cada jogador.</Text>

                                  <Text variant="headlineSmall">São 7 possibilidades de prêmios:</Text>

                                  <Text variant="bodyLarge">Quem acertar DEZ, NOVE, OITO, SETE, SEIS e CINCO números!</Text>
                                  <Text variant="bodyLarge">E quem não acertar nenhum número, também ganha!</Text>

                                  <Text variant="headlineSmall">Vou te explicar como funciona com detalhes:</Text>

                                  <Text variant="bodyLarge">Todo concurso tem data para iniciar, e sempre terá início junto com um determinado sorteio da Dupla Sena!</Text>

                                  <Text variant="bodyLarge">Em cada sorteio da Dupla Sena, os 6 números sorteados serão adicionados ao sistema SENINHA, e automaticamente será computado quantos acertos cada jogador conseguiu. Este resultado estará também no seu bilhete online!</Text>

                                  <Text variant="bodyLarge">Os sorteio serão acumulados até que algum jogador consiga fazer os 10 pontos.</Text>

                                  <Text variant="headlineSmall">ATENÇÃO! </Text>
                                  <Text variant="bodyLarge">O concurso só será finalizado quando um jogador atingir os 10 pontos. No caso de mais jogadores atingirem juntos, o prêmio será dividido.</Text>



                                  <Text variant="headlineSmall">Agora vou te explicar sobre os prêmios!</Text>


                                  <Text variant="headlineMedium">Prêmio ZERO:</Text>
                                  <Text variant="bodyLarge">Ganhará o prêmio ZERO quem não acertar nenhum número ao final do concurso, ou seja, quando alguém chegar aos 10 acertos e o jogador não tiver nenhum acerto marcado. </Text>


                                  <Text variant="headlineMedium">Prêmio CINCO:</Text>
                                  <Text variant="bodyLarge">Ganhará o prêmio CINCO, apenas que atingir cinco acertos apenas no primeiro sorteio. Veja bem, apenas no primeiro sorteio. Seis números serão adicionados ao sistema, e o jogodaor precisa acertar CINCO.</Text>


                                  <Text variant="headlineMedium">Prêmio SEIS:</Text>
                                  <Text variant="bodyLarge">Ganhará o prêmio SEIS, o jogador que conseguir fazer os seis pontos primeiro. Aqui não precisa ser no primeiro sorteio. Quem atingir SEIS acertos primeiro, levará o prêmio.</Text>
                                  <Text variant="bodyLarge">Lembrando que mais ganhadores poderão dividir o prêmio.</Text>


                                  <Text variant="headlineMedium">Prêmio SETE:</Text>
                                  <Text variant="bodyLarge">Ganhará o prêmio SETE, o jogador que conseguir fazer os SETE pontos primeiro. Quem atingir SETE acertos primeiro, levará o prêmio.</Text>
                                  <Text variant="bodyLarge">O prêmio será dividido em caso de mais ganhadores.</Text>

                                  <Text variant="headlineMedium">Prêmio OITO:</Text>
                                  <Text variant="bodyLarge">Ganhará o prêmio OITO, o jogador que conseguir fazer os OITO pontos primeiro. Quem atingir OITO acertos primeiro, levará o prêmio.</Text>
                                  <Text variant="bodyLarge">O prêmio será dividido em caso de mais ganhadores.</Text>


                                  <Text variant="headlineMedium">Prêmio NOVE:</Text>
                                  <Text variant="bodyLarge">ATENÇÃO: O prêmio NOVE só sairá quando um jogador atingir os 10 pontos. É isso mesmo! O prêmio NOVE só estará disponível no final do concurso, quando o prêmio 10 for batido.</Text>
                                  <Text variant="bodyLarge">Ganhará o prêmio NOVE, Todos os jogadores que atingirem os NOVE pontos ao final do concurso. </Text>
                                  <Text variant="bodyLarge">E muita ATENÇÃO... que vencer o prêmio 10, não leva o prêmio de NOVE.</Text>

                                  <Text variant="headlineMedium">Prêmio DEZ:</Text>
                                  <Text variant="bodyLarge">E aqui chegamos ao prêmio máximo. Ganhará o prêmio DEZ o jogador que somar 10 acertos primeiro, finalizando assim o concurso.</Text>
                                  <Text variant="bodyLarge">O jogador que ganhar o prêmio máximo, não leva o prêmio de NOVE pontos, mas pode levar os prêmios anteriores se eles atingirem conforme explicações.</Text>

                                </View>

                            </Card.Content>
                        </Card>
                    </View>
                </View>
        </ScrollView>
      
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    opacity: 0.5,
    padding: 10,
  },
  button: {
    backgroundColor: '#2196F3',
    borderRadius: 8,
    padding: 10,
    marginBottom: 10,
    width: '100%',
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default MenuScreen;
