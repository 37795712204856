import {StyleSheet} from 'react-native';

const cesta = StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 2,
    },
    headerContentLeft: {
        flexDirection: 'column',
        width: '50%',
    },
    headerContentRight: {
        flexDirection: 'column',
        width: '50%', 
        alignItems: 'flex-start',
    },
    text: {
        color: '#f7f7f7',
        fontSize: 13,
        fontWeight: 'bold',
        textTransform: 'uppercase',
    }
  });

export default cesta;